.infinite-word-slider {
    width: 100%;
    overflow: hidden;
    /* background: radial-gradient(#F900BF, #D1AFA7) ; */
}

.slider-content {
    display: flex;
    transition: transform 0.5s ease;

}

.slide {
    flex: 0 0 auto;
    padding: 20px;
    margin-right: 20px;
    transition: opacity 0.5s ease;
    color: #000;
    font: oblique bold 120% ;
}

.slide.active {
    opacity: 1;
}

